export const API_URL = process.env.NEXT_PUBLIC_API_URL

export const TRACKER_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOiJzcGRhc2giLCJleHAiOjI0NTk0OTM1MDIsImlzcyI6InNpcGxhaC1wb3J0YWwifQ.FJ9LmDpUnMJDIYUqXdQ_YONDq_49JX9lxWKstAFE5yI'

export const BASE_URL_SIPLAH =
  process.env.NEXT_PUBLIC_ENV === 'test'
    ? 'https://siplah-portal.staging.belajar.id/'
    : 'https://siplah.kemdikbud.go.id/'

export const TRACKER_BASE_URL =
  process.env.NEXT_PUBLIC_ENV === 'test'
    ? 'https://api.siplah-portal.staging.belajar.id'
    : 'https://ws.siplah.kemdikbud.go.id'

export const TRACKER_PATH = '/event-tracker/api/v1/events'
export const APP_NAME = 'siplah_portal'
export const APP_VERSION = 'v2.0'
export const TRACKER_VERSION = 'v1'
export const TRACKER_NAME = 'siplah_generic_submitted'
