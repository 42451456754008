import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'
import { setResponse } from 'utils/mirage'

import { API_URL } from 'constants/app'
import { GET_SEARCH } from 'constants/api'

import * as search_result from './search-result.json'

export function searchUsingDataHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.get(
    `${API_URL}${GET_SEARCH}`,
    setResponse(() => search_result)
  )
}
