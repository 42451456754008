import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'
import { setResponse } from 'utils/mirage'

import { API_URL } from 'constants/app'
import { GET_LOCATION } from 'constants/api'

import * as location from './location.json'

export function locationUsingDataHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.get(
    `${API_URL}${GET_LOCATION}`,
    setResponse(() => location)
  )
}
