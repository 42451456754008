import Router from 'next/router'
import NProgress from 'nprogress'

import { QueryClient, QueryClientProvider } from 'react-query'

import '@wartek-id/design-tokens/build/guru-mobile/css/color.css'
import '@wartek-id/design-tokens/build/guru-mobile/css/font.css'
import '@wartek-id/design-tokens/build/guru-mobile/css/size.css'
import 'styles/global.css'

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  const mockServer = require('configs/mocks/server').default
  mockServer({ environment: process.env.NODE_ENV })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = ({ Component, pageProps, router }) => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} key={router.route} />
      </QueryClientProvider>
    </div>
  )
}

export default App
